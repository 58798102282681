import { CloudDownloadOutlined, LoadingOutlined, SearchOutlined, ShoppingCartOutlined, UploadOutlined } from "@ant-design/icons";
import { Alert, Badge, Card, Checkbox, Col, Input, Modal, Radio, Row, Spin, Tooltip } from "antd";
import { Key, useContext, useEffect, useState } from "react";
import { customRequest } from "../../../../common/request/customRequest";
import { useFetch } from "../../../../hooks/useFetch/useFetch";
import { InternalStock } from "../../../../interfaces/InternalStock";
import { WordPressPrices } from "../../../../interfaces/WordPressPrices";
import { DrawerContext } from "../../../../store/DrawerContext/DrawerContext";
import { FilterDropdownProps } from "../../../../types/filterDropDown";
import { ROLE_ADMIN, ROLE_ECOMMERCE } from "../../../../common/constants/roles";
import AuthRoles from "../../../components/design/AuthRoles/AuthRoles";
import ButtonSG from "../../../components/design/Buttons/ButtonSG";
import TableSG from "../../../components/design/Table/TableSG";
import PriceLogDrawer from "../../../components/model/loglsDrawer/PriceLogDrawer";
import { PriceChart } from "./chart/PriceChart";
import { BuyInternalStockModal } from "./modal/BuyInternalStockModal";
import { ChangePriceModal } from "./modal/ChangePriceModal";
import { PriceTag } from "../../../components/design/PriceTag/PriceTag";
import { ALERT } from "../../../../common/constants/alertSyncProducts";
import { formatDaysAgo } from "../../../../common/utils/formatDate";
import { useIsAuthorized } from "../../../../common/request/AuthorizationUtils";
import { WordPressStock } from "../../../../types/WordPressStock";
import { ChangeWordPressStockModal } from "./modal/ChangeWordPressStockModal";
import { authRequest } from "../../../../common/request/authRequest";

export const SyncProducts = () => {

  const IsAuthorized = useIsAuthorized();

  const [updateData, setUpdateData] = useState(false);
  const [wordPressPrices, setWordPressPrices] = useState<WordPressPrices | null>(null);
  const [wordPressStock, setWordPressStock] = useState<WordPressStock | null>(null);
  const [internalStock, setInternalStock] = useState<InternalStock | null>(null);
  const [selectedRecord, setSelectedRecord] = useState<WordPressPrices | null>(null);
  const [priceLog, setPriceLog] = useState<WordPressPrices | null>(null);
  const [webs, setWebs] = useState<any>([]);
  const [webFilter, setWebFilter] = useState<any>(null);
  const [isSynchronizing, setIsSynchronizing] = useState(false);
  const [isAutomaticSync, setIsAutomaticSync] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [indeterminate, setIndeterminate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [filteredRows, setFilteredRows] = useState([]);
  const [storesData, setStoresData] = useState<any>(null);
    
  useEffect(() => {
          
    authRequest(`${process.env.REACT_APP_API}/combos/webs`, {}).then((res:any) => {
      
      setWebs(res);
      setWebFilter(`?web=${res[0].name}`);
      setUpdateData(!updateData);
    })        
    
  }, []);

  useEffect(() => {
              
    if (webFilter) {
      authRequest(`${process.env.REACT_APP_API}/wordPressProducts/${webFilter}`, {}).then((res:any) => {                
          setIsLoading(true);
          setStoresData(res);
          setIsLoading(false);
        })        
    }
  }, [updateData]);

  const sync = async () => {
    await customRequest(`${process.env.REACT_APP_API}/wordPress`, { method: 'PUT' })
      .then(() => {
        setUpdateData(!updateData);
        setIsSynchronizing(false);
      })
      .catch(() => {
        setIsSynchronizing(false);
      });
  }

  function selectWeb(web: any) {
    
    setWebFilter(`?web=${web.target.value}`);
    setUpdateData(!updateData);
  }

  const productsToUpload = storesData?.productsList.filter((obj: { id: Key; }) => selectedRowKeys?.includes(obj.id))

  const products = productsToUpload && productsToUpload.map((object: any) => {
    const discountPrice = ((1 - object.priceParams.discount) * object.suggestedPrice);
    return {
      id: object.id,
      sku: object.sku,
      webId: object.webId,
      wordPressId: object.wordPressId,
      regularPrice: object.suggestedPrice,
      salePrice: discountPrice,
      suggestedPrice: object.suggestedPrice,
      suggestedSalePrice: discountPrice
    };
  });


  const uploadPrice = async () => {

    await customRequest(`${process.env.REACT_APP_API}/wordPressProducts/prices`, { method: 'PATCH', data: products }, 'Precio subido con éxito', 'No se han podido subir los precios').then(() => {
      setUpdateData(!updateData);
      setIsAutomaticSync(false);
    })
      .catch(() => {
        setIsAutomaticSync(false);
      });
  }

  const { setProductDrawer } = useContext(DrawerContext)


  const onSelectChange = (selectedRowKeys: any) => {
    setSelectedRowKeys(selectedRowKeys);
    setIndeterminate(true);
  }
    
  const columns = [
    {
      title: '',
      filters: [
        { text: 'Automático', value: "automatic" },
        { text: 'Manual', value: "manual" },
        { text: 'Incompleto', value: "incomplete" }
      ],

      onFilter: (value:any, record:any) => {
        if (value === 'automatic' && record.suggestedPrice && record.priceParams.automaticSync) {
          return true;
        }
        if (value === 'manual' && record.suggestedPrice && !record.priceParams.automaticSync) {
          return true;
        }
        if (value === 'incomplete' && !record.suggestedPrice) {
          return true;
        }
      }
    },
    {
      title: 'Producto',
      key: 'name',
      dataIndex: 'name',
      width: '25%',
      render: (_: any, record: any) => (
        <>
          {record.suggestedPrice ?
            <Badge.Ribbon text={record.priceParams.automaticSync ? "Automático" : "Manual"} color="#00c39a" >
              <Card size="small" style={{ borderRadius: '10px' }}>
                <small>
                  <b>{record.webId}</b>
                  <div>{record.name}</div>
                  <div> <a onClick={() => setProductDrawer(record.sku)}>{record.sku}</a></div>
                </small>
              </Card>
            </Badge.Ribbon>
            :
            <Badge.Ribbon text="Incompleto" color="var(--grey)" >

            <Card size="small" style={{ borderRadius: '10px' }}>
              <small>
                <b>{record.webId}</b>
                <div>{record.name}</div>
                <div> <a onClick={() => setProductDrawer(record.sku)}>{record.sku}</a></div>
              </small>
            </Card>
            </Badge.Ribbon>

          }
        </>
      ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
      }: FilterDropdownProps) => {
        return (
          <Input
            autoFocus
            placeholder="Nombre o sku"
            value={selectedKeys[0]}
            onChange={(e: any) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          ></Input>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value: any, record: any) => {
        return record.name.toLowerCase().includes(value.toLowerCase()) || record.sku.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      title: 'Stock',
      key: 'Stock',
      dataIndex: 'alerts',
      children: [
        {
          title: 'WordPress',
          dataIndex: 'stockQuantity',
          key: 'stockQuantity',
          width: 150,
          align: 'center',
          render: (_: any, record: any) => {
            return <div style={{ display: 'flex', gap: '5px' }}>
              <p>{record.stockQuantity}uds</p>
              <ButtonSG size="small" color="yellow" onClick={() => { setWordPressStock(record) }}>
                <UploadOutlined />
              </ButtonSG>
            </div>

          },
          sorter: (a: any, b: any) => {
            const stockA = parseInt(a.stockQuantity);
            const stockB = parseInt(b.stockQuantity);
            return stockA - stockB;
          },
        },
        {
          title: 'Logística',
          dataIndex: 'internalStock',
          key: 'internalStock',
          width: 200,
          align: 'center',
          render: (_: any, record: any) => {

            const alertWithNoRef = record.alerts.find((alert: { description: string; }) => alert.description === ALERT.NO_REFERENCE);

            return <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
              <div style={{ display: 'flex', gap: '5px' }}>
                <p>{record.internalStock}uds</p>
                {!alertWithNoRef && (
                  <Badge size="small" count={record.numPetitions} color="#00c39a">
                    <ButtonSG size="small" color="yellow" onClick={() => { setInternalStock(record); }}>
                      <ShoppingCartOutlined />
                    </ButtonSG>
                  </Badge>
                )}
              </div>
            </div>
          },
          sorter: (a: any, b: any) => a.internalStock - b.internalStock,
        },
      ],

    },
    {
      title: 'Precio',
      key: 'price',
      dataIndex: 'price',
      children: [
        {
          title: 'WordPress',
          dataIndex: 'regularPrice',
          key: 'regularPrice',
          align: 'center',
          width: 150,
          render: (_: any, record: any) => {
            return <> {record.salePrice ?
              (<div>
                <Tooltip title={<s>Antes: {Number(record.regularPrice).toFixed(2)}€</s>}>
                  <Badge size="small" dot={true} color="#00c39a" style={{ marginRight: '7px' }}>
                    <PriceTag price={record.salePrice} ></PriceTag>
                    <small style={{ display: 'block' }}></small>
                  </Badge>
                </Tooltip>
              </div>)
              : (<PriceTag price={record.regularPrice} />)
            }</>
          },
          sorter: (a: any, b: any) => {
            const aPrice = a.salePrice || a.regularPrice;
            const bPrice = b.salePrice || b.regularPrice;
            return aPrice - bPrice;
          }
          ,
        },
        {
          title: 'Sugerido',
          dataIndex: 'suggestedPrice',
          key: 'suggestedPrice',
          width: 150,
          align: 'center',
          sorter: (a: any, b: any) => a.suggestedPrice - b.suggestedPrice,
          render: (_: any, record: any) => {
            return <> {
              <AuthRoles roles={[ROLE_ECOMMERCE.WRITE, ROLE_ADMIN]}>
                <div style={{ display: 'flex', gap: '5px', justifyContent: 'space-between ' }}>

                  {
                    (record.suggestedPrice && record.suggestedPrice > 0) && (

                      (record.priceParams.discount && record.priceParams.discount > 0) ?
                        (
                          <div>
                            <Tooltip title={<s>Antes: {Number(record.suggestedPrice).toFixed(2)}€</s>}>
                              <Badge size="small" dot={true} color="#00c39a" style={{ marginRight: '7px' }}>
                                <PriceTag price={record.suggestedPrice * (1 - record.priceParams.discount)} />
                              </Badge>
                              <small style={{ display: 'block' }}></small>
                            </Tooltip>
                          </div>
                        )
                        : (
                          <PriceTag price={record.suggestedPrice} />
                        )
                    )
                  }

                  {(!record.priceParams.automaticSync && record.priceParams.basePrice) &&
                    <ButtonSG size="small" color="yellow" onClick={() => { setWordPressPrices(record) }}>
                      <UploadOutlined />
                    </ButtonSG>}

                </div>
              </AuthRoles>
            }
            </>
          },
        },
      ],
    },
    {
      title: 'Alertas',
      key: 'alerts',
      dataIndex: 'alerts',
      width: '20%',
      filters: [
        { text: 'Error', value: 'error' },
        { text: 'Warning', value: 'warning' },
        {text: 'Info', value: 'info'},
        {text: 'Sin alertas', value:'no_alerts'}
      ],
      onFilter: (value: any, record: { alerts: any[] }) => {
        if (value === 'no_alerts') {
          return record.alerts && record.alerts.length === 0;
        } else {
          return record.alerts && record.alerts.some(element => element.severity === value);
        }
      },      
      render: (_: any, record: any) => (
        <>
          {
            record.alerts && record.alerts.map((element: any) =>
              <Alert type={element.severity} message={<small>{element.description}</small>} showIcon style={{ margin: '4px 10px', padding: '2px', borderRadius: '5px' }} />
            )}
        </>
      )
    },
    {
      title: '',
      width: '5%',
      render: (_: any, record: any) => {
        return (
          <>
            {
              record.sku ?
                (<ButtonSG color="no-color" onClick={() => {
                  setSelectedRecord(record)
                }}>
                  {<span className="material-symbols-rounded">chart_data</span>}
                </ButtonSG>) : ""
            }

          </>
        )
      }
    },
    {
      title: '',
      key: 'logs',
      dataIndex: 'logs',
      align: 'center',
      width:'10%',
      render: (_: any, record: any) => (
        <div style={{display:'flex', flexDirection:'column'}}>
          {
            <a onClick={() => {
              setPriceLog(record)

            }}>
              <strong>Logs</strong>
            </a>
          }
          <small>{record.lastPriceMessage}</small>
        </div>
      )
    }
  ]
  const onSelectAllCustom = (selected: boolean) => {
    const selectedKeys = selected
      ? filteredRows?.reduce((keys: any[], row: any) => {
        if (!(row.suggestedPrice === null || row.priceParams.automaticSync)) {          
            keys.push(row.id);
        }
        return keys;
      }, [])
      : [];

    setSelectedRowKeys(selectedKeys);
    setIndeterminate(false);
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    columnTitle: storesData?.productsList?.id ?
      <Checkbox disabled={true}></Checkbox>
      :
      <Checkbox indeterminate={indeterminate} onChange={(e) => onSelectAllCustom(e.target.checked)}></Checkbox>,
    getCheckboxProps: (record: any) => ({
      disabled: record.suggestedPrice === null || record.priceParams.automaticSync,
      name: record.id,
    }),
  }
  return (
    <>
      <Card style={{ border: 'none' }} bodyStyle={{ padding: "0px 10px 10px 10px" }}>

        <div className="table-header" style={{ paddingRight: 0 }}>
          <span className='title-summary'>Productos de WordPress</span>
        </div>
      
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px', alignItems: 'center' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <ButtonSG color='grey' size='small' disabled={!IsAuthorized([ROLE_ADMIN, ROLE_ECOMMERCE.WRITE, ROLE_ECOMMERCE.WRITE_SYNC])}
              onClick={() => {
                setIsSynchronizing(true);
                sync();
              }}>
              {!isSynchronizing ? (
                <>
                  <CloudDownloadOutlined />
                  <span style={{ fontSize: '11px' }}> 24h</span>
                </>
              ) : <Spin indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />} />}
            </ButtonSG>
            {storesData && storesData.minutesSinceLastSync != null &&
              <small style={{ color: 'grey', position: 'absolute', left: '10px', top: '98px' }}>Sincronizado hace {formatDaysAgo(storesData.minutesSinceLastSync)}</small>
            }
          </div>
          
          {webs && webs[0] && <Radio.Group defaultValue={webs[0].name} buttonStyle="outline" onChange={(e) => selectWeb(e)}>
            {
              webs && webs.map((element: any) =>
                <Radio.Button value={element.name}>{element.name}</Radio.Button>
              )}
          </Radio.Group>}

          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <ButtonSG disabled={!(selectedRowKeys?.length > 0)} size="small" style={{ width: '120px' }}
              onClick={
                () => {
                  Modal.confirm({
                    title: '¿Confirma la subida de estos precios?',
                    okText: 'Confirmar',
                    cancelText: 'Cancelar',
                    onOk() {
                      setIsAutomaticSync(true);
                      uploadPrice()
                    }
                  });
                }
              }
            >
              {!isAutomaticSync ? "Subir Precios" : <Spin indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />} />}
            </ButtonSG>
            {!isLoading && <span style={{ color: 'grey', position: 'absolute', top: '98px', right:'10px' }}>{`Seleccionados ${selectedRowKeys?.length} de ${storesData?.productsList?.length}`}</span>}
          </div>
        </div>

        <TableSG rowSelection={rowSelection} columns={columns} dataSource={storesData?.productsList} rowKey={'id'} loading={isLoading} 
        onChange={(pagination: any, filters: any, sorter: any, extra: any) => {
          setFilteredRows(extra.currentDataSource);
          //debugger;
     } } />
      </Card>
      {
        wordPressPrices != null && <ChangePriceModal updateData={updateData} setUpdateData={setUpdateData} wordPressPrices={wordPressPrices} onFinish={() => { setWordPressPrices(null); }} />
      }
      {
        wordPressStock != null &&
        <ChangeWordPressStockModal updateData={updateData} setUpdateData={setUpdateData} wordPressStock={wordPressStock} onFinish={() => { setWordPressStock(null); }} />
      }

      {
        selectedRecord !== null &&
        <Modal
          open={selectedRecord !== null}
          onCancel={() => {
            setSelectedRecord(null)
          }}
          footer={null}
          width='100%'
        >
          <PriceChart selectedRecord={selectedRecord} />
        </Modal>
      }
      {
        internalStock != null && <BuyInternalStockModal updateData={updateData} setUpdateData={setUpdateData} internalStock={internalStock} onFinish={() => { setInternalStock(null); }} />
      }
      {
        priceLog !== null && (
          <PriceLogDrawer
            onClose={() => {
              setPriceLog(null)
            }
            }
            visible={priceLog !== null}
            nameLog={priceLog.name}
            wordPressId={priceLog.wordPressId}
            webId={priceLog.webId}
          />
        )
      }
    </>
  )
};